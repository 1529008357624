import React from 'react'
import { useConsole } from 'hooks/useConsole'
import Setting from 'components/Setting'
import Console from 'components/Console'
import Toolbar from 'components/Toolbar'

function App() {
  const { connected } = useConsole()

  if (!connected) {
    return <Setting />
  } else {
    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, width: '100%', position: 'relative' }}>
          <Console />
        </div>
        <Toolbar />
      </div>
    )
  }
}

export default App
