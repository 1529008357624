import React, { useRef, useEffect } from 'react'
import { useConsole } from 'hooks/useConsole'

export default function Console() {
  const videoRef = useRef(null)
  const { socket, stream, keyboardDisabled, resolution } = useConsole()

  useEffect(() => {
    const video = videoRef.current
    video.srcObject = stream

    function onmouseenter(event) {
      const [w, h] = (resolution || '1280x720').split('x').map(x => parseInt(x))
      const X = Math.max(0, (w / video.offsetWidth) * event.offsetX)
      const Y = Math.max(0, (h / video.offsetHeight) * event.offsetY)
      socket.emit('event', { event: 'mouseenter', X, Y })
    }
    function onmousemove({ movementX, movementY }) {
      if (movementX || movementY) {
        const [w, h] = (resolution || '1280x720').split('x').map(x => parseInt(x))
        const X = movementX ? ((movementX * w) / video.offsetWidth) * 2 : 0
        const Y = movementY ? ((movementY * h) / video.offsetHeight) * 2 : 0
        socket.emit('event', { event: 'mousemove', X, Y })
      }
    }
    function onmousedown(event) {
      socket.emit('event', { event: 'mousedown', eventType: event.type, button: event.button })
    }
    function onmouseup(event) {
      socket.emit('event', { event: 'mouseup', eventType: event.type, button: event.button })
    }
    function oncontextmenu(event) {
      event.preventDefault()
    }
    function onwheel(event) {
      event.preventDefault()
      socket.emit('event', { event: 'wheel', X: event.deltaX, Y: event.deltaY })
    }
    function onkeydown(event) {
      event.preventDefault()
      socket.emit('event', {
        event: 'keydown',
        eventType: event.type,
        code: event.code,
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        altKey: event.altKey,
        metaKey: event.metaKey,
      })
      if (['Backquote', 'KanaMode', 'CapsLock'].includes(event.code)) {
        socket.emit('event', {
          event: 'keyup',
          code: event.code,
          ctrlKey: event.ctrlKey,
          shiftKey: event.shiftKey,
          altKey: event.altKey,
          metaKey: event.metaKey,
        })
      }
    }
    function onkeyup(event) {
      event.preventDefault()
      if (!['Backquote', 'KanaMode', 'CapsLock'].includes(event.code)) {
        socket.emit('event', {
          event: 'keyup',
          eventType: event.type,
          code: event.code,
          ctrlKey: event.ctrlKey,
          shiftKey: event.shiftKey,
          altKey: event.altKey,
          metaKey: event.metaKey,
        })
      }
    }

    video.addEventListener('mouseenter', onmouseenter)
    video.addEventListener('mousemove', onmousemove)
    video.addEventListener('mousedown', onmousedown)
    video.addEventListener('mouseup', onmouseup)
    video.addEventListener('contextmenu', oncontextmenu)
    video.addEventListener('wheel', onwheel)
    if (!keyboardDisabled) {
      document.addEventListener('keydown', onkeydown)
      document.addEventListener('keyup', onkeyup)
    }

    return () => {
      video.removeEventListener('mouseenter', onmouseenter)
      video.removeEventListener('mousemove', onmousemove)
      video.removeEventListener('mousedown', onmousedown)
      video.removeEventListener('mouseup', onmouseup)
      video.removeEventListener('contextmenu', oncontextmenu)
      video.removeEventListener('wheel', onwheel)
      if (!keyboardDisabled) {
        document.removeEventListener('keydown', onkeydown)
        document.removeEventListener('keyup', onkeyup)
      }
    }
  }, [socket, stream, keyboardDisabled, resolution])

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'none' }}
      />
    </div>
  )
}
