import React, { useState, useEffect } from 'react'
import { useConsole } from 'hooks/useConsole'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'

export default function Setting() {
  const [roomId, setRoomId] = useState('')
  const [password, setPassword] = useState('')
  const [videoCodec, setVideoCodec] = useState('*')
  const { stream, connect, setConnected } = useConsole()
  const [connecting, setConnecting] = useState(false)

  const handleConnect = () => {
    connect({ roomId, videoCodec, password })
    setConnecting(true)
  }

  const isConnected = Boolean(stream)
  useEffect(() => {
    if (connecting && !isConnected) {
      setTimeout(() => {
        // reconnect
        connect({ roomId, videoCodec, password })
      }, 500)
    }
  }, [isConnected])

  return (
    <Dialog
      open={!stream}
      onExited={() => setConnected(true)}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' }}
    >
      <DialogContent style={{ padding: 32, width: 240, position: 'relative' }}>
        <TextField
          label="接続先ID"
          style={{ display: 'block' }}
          variant="outlined"
          value={roomId}
          onChange={event => setRoomId(event.target.value)}
          fullWidth
          disabled={connecting}
        />
        <TextField
          label="パスワード"
          style={{ display: 'block', marginTop: 16 }}
          type="password"
          variant="outlined"
          value={password}
          onChange={event => setPassword(event.target.value)}
          fullWidth
          disabled={connecting}
        />
        {/*
        <TextField
          label="コーデック"
          select
          style={{ display: 'block', marginTop: 16 }}
          variant="outlined"
          SelectProps={{ native: true }}
          value={videoCodec}
          onChange={event => setVideoCodec(event.target.value)}
          fullWidth
          disabled={connecting}
        >
          <option value="*">指定なし</option>
          <option value="H264">H264</option>
          <option value="VP8">VP8</option>
          <option value="VP9">VP9</option>
        </TextField>
        */}
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          style={{ marginTop: 16, height: 50 }}
          onClick={handleConnect}
          disabled={connecting}
        >
          接続する
        </Button>
        {connecting && !stream && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress thickness={4} size={60} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
