import { useState, useCallback } from 'react'

export function useLocalState(initialState, key) {
  const [value, setValue] = useState(
    localStorage[key] !== undefined ? JSON.parse(localStorage[key]) : initialState
  )

  const _setValue = useCallback(
    value => {
      setValue(value)
      localStorage[key] = JSON.stringify(value)
    },
    [key]
  )

  return [value, _setValue]
}
