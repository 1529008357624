import React, { useState, useEffect, useRef } from 'react'
import { useConsole } from 'hooks/useConsole'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'

const resolutions = [
  '1024x768',
  '1280x720',
  '1440×1080',
  '1600x1200',
  '1920x1080',
  '1920x1080',
  '2048×1536',
  '2560×1440',
]

const useStyles = makeStyles(theme => ({
  root: {
    height: 60,
    background: '#222',
    color: '#ddd',
    display: 'flex',
    alignItems: 'center',
    transform: 'translateY(100%)',
    transition: 'transform .2s linear',
  },
  button: {
    height: '100%',
    textAlign: 'center',
    display: 'block',
    padding: 0,
    lineHeight: 'inherit',
  },
}))

export default function Toolbar() {
  const ref = useRef(null)
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    disconnect,
    keyboardDisabled,
    setKeyboardDisabled,
    resolution,
    setResolution,
  } = useConsole()

  const handleSelectResolution = resolution => {
    setResolution(resolution)
    setAnchorEl(null)
  }

  useEffect(() => {
    setTimeout(() => {
      ref.current.style.transform = 'translateY(0%)'
    }, 0)
  }, [])

  const handleDisconnect = () => {
    ref.current.style.transform = 'translateY(100%)'
    setTimeout(() => {
      disconnect()
    }, 300)
  }

  return (
    <div className={classes.root} ref={ref}>
      <Button
        className={classes.button}
        style={{ width: 100, color: keyboardDisabled ? '#666' : '#eee' }}
        onClick={() => setKeyboardDisabled(!keyboardDisabled)}
      >
        <KeyboardIcon />
        <div>キー{keyboardDisabled ? '無効' : '有効'}</div>
      </Button>
      <Button
        className={classes.button}
        style={{ width: 100 }}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <AspectRatioIcon />
        <div>{resolution}</div>
      </Button>
      <div style={{ flex: 1 }} />
      <Button onClick={handleDisconnect} style={{ color: '#f00', height: '100%', width: 120 }}>
        切断する
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {resolutions.map((x, i) => (
          <MenuItem key={i} selected={x === resolution} onClick={() => handleSelectResolution(x)}>
            {x}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
