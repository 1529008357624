import io from 'socket.io-client'
import { connection as createConnection } from '@open-ayame/ayame-web-sdk'
import React, { useState, useCallback, useContext, createContext } from 'react'
import { useLocalState } from 'hooks/useLocalState'

const socket = io('wss://okremote.drs.app')
const ConsoleContext = createContext()

export function ConsoleProvider({ children }) {
  const [connected, setConnected] = useState(false)
  const [connection, setConnection] = useState(null)
  const [stream, setStream] = useState(null)
  const [keyboardDisabled, setKeyboardDisabled] = useLocalState(false, 'keyboardDisabled')
  const [resolution, setResolution] = useLocalState('1280x720', 'resolution')

  const connect = useCallback(({ roomId, videoCodec, password }) => {
    const options = {
      audio: { direction: 'recvonly', enabled: true },
      video: { direction: 'recvonly', enabled: true },
      iceServers: [
        { urls: 'stun:okremote-turn.drs.app:3478' },
        { urls: 'turn:okremote-turn.drs.app:3478?transport=tcp', credential: 'amz4n5zK0dyp', username: 'turnuser' },
        { urls: 'turns:okremote-turn.drs.app:443?transport=tcp', credential: 'amz4n5zK0dyp', username: 'turnuser' },
      ],
      clientId: String(Math.floor(Math.random() * Math.pow(10, 17))).padStart(17, '0'),
      signalingKey: password,
    }
    if (['H264', 'VP8', 'VP9'].includes(videoCodec)) {
      options.video.codec = videoCodec
    }


    const connection = createConnection('wss://okremote.drs.app/signaling', roomId, options)
    connection.on('disconnect', () => {
      // reconnect
      setStream(null)
      setConnection(null)
      setConnected(false)
    })
    connection.on('addstream', ({ stream }) => {
      setStream(stream)
    })
    connection.on('open', () => {
      setConnection(connection)
    })
    console.log('connecting')
    connection.connect()
    console.log('socket emitting')
    socket.emit('registClient', roomId)
  }, [])

  const disconnect = () => {
    if (connection) {
      connection.disconnect()
    }
  }

  return (
    <ConsoleContext.Provider
      value={{
        connect,
        disconnect,
        connected,
        setConnected,
        socket,
        stream,
        keyboardDisabled,
        setKeyboardDisabled,
        resolution,
        setResolution,
      }}
      children={children}
    />
  )
}

export function useConsole() {
  return useContext(ConsoleContext)
}
