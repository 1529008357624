import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { teal, amber, red } from '@material-ui/core/colors'
import { ConsoleProvider } from 'hooks/useConsole'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: 'https://b9281f9a4a1a43dd999db5be9cb34405@o4504337914265600.ingest.sentry.io/4504337915052032',
  integrations: [new BrowserTracing(), new CaptureConsoleIntegration(
    {
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
    }
  )],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: teal,
    secondary: amber,
    error: red,
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ConsoleProvider>
      <App />
    </ConsoleProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
